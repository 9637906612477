'use strict';

const cache = {
    $selectorSwiper: '.js-pd-swiper',
    $selectorSwiperData: $('.js-pd-swiper')
};

$(window).on('load', function () {
    let configSwiperGeneric = cache.$selectorSwiperData.data('swiper-config');

    if (configSwiperGeneric) {
        initSwiper(configSwiperGeneric);
    }

});

function initSwiper(config) {
    const swiper = new Swiper(cache.$selectorSwiper, {
        modules: [Autoplay, Navigation, Pagination, Scrollbar],
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        autoplay: config.autoplay && (config.slides > 1) ? {
            delay: config.delay
        } : false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: !config.autoplay
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });

    if (config.delay) {
        $(swiper.el).find('.swiper-pagination-bullet').attr('style', '--speed: ' + config.delay / 1000 + ';');
    }
}
