'use strict';

const cache = {
    $body: $('body'),

    $btnHamburger: $('.js-icon-hamburger'),
    $btnCloseMenu: $('.js-btn-close-menu'),
    $navsMenu: $('.js-navs-menu'),

    $btnCategory: $('.js-btn-category'),
    $btnSubCategory: $('.js-nav-btn-subCategory'),

    $linkCategory: $('.js-link-category'),
    $linkSubCategory: $('.js-nav-link-subCategory'),
    $linkLastCategory: $('.js-nav-link-last-category'),

    $rowMenu: $('.js-header .js-row-menu'),
    $colCategory: $('.js-col-category'),
    $colSubCategory: $('.js-sub-category'),
    $colLastSubCategory: $('.js-last-subcategory'),

    $linkCountry: $('.js-redirect-site'),

    $header: $('.js-header'),
    $BlockIntersection: $('.js-block-component'),
    $logoRed: $('.js-logo-red'),
    $logoWhite: $('.js-logo-white'),
    $logoMobile: $('.js-logo-mobile'),

    $btnBackMenu: $('.js-back-menu'),
    $headingTitleMenu: $('.js-title'),

    $campaignBanner: $('.js-header-banner'),
    $campaignBannerClose: $('.js-promo-banner-close'),

    $pagePd: $('.js-storepage'),
    $page: $('.page'),

    $fullHeightComponent: $('.full-height'),
    $btnCountrySelector: $('.js-btn-country'),

    $firstCol: $('#first-menu-col'),
    $countryCollapse: $('.js-collapse-country')
};

// global variables
var campaignBannerStatus = window.sessionStorage.getItem('hide_header_banner');
var heightPositionY;
var headerStatus = '';

function calculateHeaderColors() {
    if (cache.$BlockIntersection.length > 0) {
        let triggerBlockBottom = cache.$BlockIntersection.offset().top + cache.$BlockIntersection.height();

        if (triggerBlockBottom < $(window).scrollTop()) {
            cache.$header.removeClass('pinned');
            cache.$logoRed.removeClass('hide-logo');
            cache.$logoWhite.addClass('hide-logo');

            // check logo mobile
            if (!$('.js-logo-red-mobile').hasClass('js-show-only-red')) {
                cache.$logoMobile.removeClass('pinned');
                $('.js-logo-red-mobile').removeClass('hide-logo');
                $('.js-logo-white-mobile').addClass('hide-logo');
            } else {
                cache.$logoMobile.removeClass('pinned');
            }

            headerStatus = 'FILL';
        } else if (triggerBlockBottom >= $(window).scrollTop()) {
            cache.$header.addClass('pinned');
            cache.$logoWhite.removeClass('hide-logo');
            cache.$logoRed.addClass('hide-logo');

            // check logo mobile
            if (!$('.js-logo-red-mobile').hasClass('js-show-only-red')) {
                cache.$logoMobile.addClass('pinned');
                $('.js-logo-red-mobile').addClass('hide-logo');
                $('.js-logo-white-mobile').removeClass('hide-logo');
            } else {
                cache.$logoMobile.removeClass('pinned');
            }

            headerStatus = 'TRANSPARENT';
        } else if (window.isMobile()) {
            cache.$header.removeClass('pinned hide');
        }
    }
}

function calculateComponentHeight() {
    if (cache.$fullHeightComponent.length > 0) {
        let campaignBannerHeight = cache.$campaignBanner && cache.$campaignBanner[0]
            ? cache.$campaignBanner[0].offsetHeight
            : 0;

        if (window.isMobile()) {
            // mobile
            if ($('.pd-search-show .title-category').length > 0) {
                let el = $('.pd-search-show .title-category')[0];

                let styles = window.getComputedStyle(el);
                let margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

                cache.$fullHeightComponent[0].style.height = "calc(100svh - " + campaignBannerHeight + "px - " + cache.$header[0].offsetHeight + "px - " + Math.ceil(el.offsetHeight + margin) + "px)";
            } else {
                cache.$fullHeightComponent[0].style.height = "calc(100svh - " + campaignBannerHeight + "px - " + cache.$header[0].offsetHeight + "px)";
            }
        } else {
            cache.$fullHeightComponent[0].style.height = "calc(100vh - " + campaignBannerHeight + "px)";
        }
    }
}

function campaignBannerCheck() {
    calculateComponentHeight();

    if (cache.$fullHeightComponent.length > 0) {
        $(window).resize(function () {
            calculateComponentHeight();
        });
    }
}

$('.modal').on('shown.bs.modal', function () {
    calculateComponentHeight();
});

$('.modal').on('hidden.bs.modal', function () {
    calculateComponentHeight();
});

$(document).ready(function () {
    AddTopHeaderDesktop();
    AddTopHeaderLogoMobile();

    // resetCampaignBannerSessionToken logic
    if (window.resetCampaignBannerSessionToken) {
        window.sessionStorage.removeItem('hide_campaign_banner');
    }

    // check if campaign banner should be removed or not
    campaignBannerCheck();

    if ((!campaignBannerStatus || campaignBannerStatus < 0) && !window.hideCampaignBanner) {
        // header banner is visible
        cache.$campaignBanner.removeClass('d-none');

        if (!window.isMobile()) {
            cache.$header.removeClass('header-top');
        } else {
            cache.$logoMobile.removeClass('header-top');
        }
    } else {
        cache.$campaignBanner.addClass('d-none');

        if (!window.isMobile()) {
            cache.$header.addClass('header-top');
        } else {
            cache.$logoMobile.addClass('header-top');
        }
    }

    // calculateHeaderColors();
    calculateComponentHeight();
});

// HEADER-BANNER RESIZING LOGIC
$(window).resize(function () {
    if (cache.$campaignBanner.hasClass('d-none')) {
        cache.$header.addClass('header-top');
        cache.$logoMobile.addClass('header-top');
    }

    AddTopHeaderDesktop();
    AddTopHeaderLogoMobile();
    calculateHeaderColors();
});

// scroll check for the top of the header
$(window).scroll(function () {
    AddTopHeaderDesktop();
    AddTopHeaderLogoMobile();
    calculateHeaderColors();
});

// MENU OPENING LOGIC
cache.$btnHamburger.on('click', function (e) {
    e.preventDefault();

    heightPositionY = window.scrollY;

    if (window.isMobile()) {
        cache.$header.addClass('mobile');
    }

    cache.$navsMenu.removeClass('hide');

    if (cache.$page.data('action') === 'Product-Show') {
        cache.$header.addClass('open-menu-page');
    }

    cache.$body.addClass('menu-open');
    calculateComponentHeight();

    setTimeout(function () {
        cache.$colCategory.addClass('translated');
    }, 100);
});

// MENU CLOSING CLICK BTN CLOSE
cache.$btnCloseMenu.on('click', function () {
    closeMenu();
});

// MENU CLOSING CLICK ROW OVERLAY
cache.$rowMenu.on('click', function (e) {
    var target = e.target.className;

    if (target.includes('icon icon-arrow-right')) {
        return;
    }

    if (!target.includes('icon icon-arrow-bottom')) {
        if (target.includes('row')) {
            closeMenu();
        }
    }
});

// CLICK IN THE FIRST CATEGORY (CHECK DESKTOP - MOBILE)
cache.$btnCategory.on('click', function (e) {
    var btnCategory = $(this);

    var nameCategory;
    if (btnCategory.data('name-category')) {
        nameCategory = btnCategory.data('name-category').toUpperCase();
    }
    if (btnCategory.hasClass('active')) {
        btnCategory.removeClass('active');
        cache.$colSubCategory.removeClass('translated');
        cache.$colLastSubCategory.removeClass('translated translated-mobile');
        cache.$btnCategory.removeClass('not-active');
        cache.$linkCategory.removeClass('not-active');
        cache.$btnSubCategory.removeClass('not-active active');
        e.stopPropagation();
    }

    if (window.isMobile()) {
        cache.$colCategory.addClass('hide');
        cache.$btnBackMenu.removeClass('hide');
        cache.$headingTitleMenu.html(nameCategory);
        cache.$btnBackMenu.attr('state-current', 'col-sub-category');
        cache.$btnBackMenu.attr('state-prev', 'col-category');
        cache.$btnBackMenu.attr('state-name-prev', nameCategory);
        cache.$btnCategory.removeClass('not-active active');
        cache.$linkCategory.removeClass('not-active active');
    }
});

cache.$linkCategory.on('click', function () {
    var btnCategory = $(this);
    btnCategory.addClass('active');
});

cache.$linkSubCategory.on('click', function () {
    var btnSubCategory = $(this);
    if (!btnSubCategory.hasClass('btn-all-category')) {
        btnSubCategory.addClass('active');
    }
});

cache.$linkLastCategory.on('click', function () {
    var btnLastCategory = $(this);
    if (!btnLastCategory.hasClass('btn-all-category')) {
        btnLastCategory.addClass('active');
    }
});

// MAKE THE REDIRECT EXECUTED WHICH IS BLOCKED BY THE SHOW EVENT OF THE TAB PANE
cache.$btnCategory.on('show.bs.tab', function () {
    var tag = $(this).prop('tagName');
    if (tag !== 'A') {
        cache.$colSubCategory.addClass('translated');
        cache.$btnCategory.not(this).addClass('not-active');
        cache.$linkCategory.addClass('not-active');
        $(this).removeClass('not-active');
        cache.$colLastSubCategory.removeClass('translated translated-mobile');
        cache.$btnSubCategory.removeClass('not-active');
        cache.$linkSubCategory.removeClass('not-active');
    }
});

// CLICK IN THE SECOND CATEGORY (CHECK DESKTOP - MOBILE)
cache.$btnSubCategory.on('click', function (e) {
    var btnSubCategory = $(this);

    if (btnSubCategory.hasClass('active')) {
        btnSubCategory.removeClass('active');
        cache.$colLastSubCategory.removeClass('translated translated-mobile');
        cache.$btnSubCategory.removeClass('not-active not-active-show');
        cache.$linkSubCategory.removeClass('not-active not-active-show');
        if (window.isMobile()) {
            cache.$colCategory.removeClass('hide');
            cache.$linkSubCategory.removeClass('not-active active not-active-show');
        }
        e.stopPropagation();
    }

    if (window.isMobile()) {
        cache.$colSubCategory.removeClass('translated');
        cache.$btnSubCategory.removeClass('not-active active not-active-show');
        cache.$linkSubCategory.removeClass('not-active active not-active-show');
    }
});

// CHECK OF THE COLUMNS TO BE SHOWN AT THE SHOW EVENT OF THE PANE TAB
cache.$btnSubCategory.on('shown.bs.tab', function () {
    var nameCategory = $(this).data('name-category').toUpperCase();
    cache.$btnSubCategory.not(this).removeClass('active');
    cache.$linkSubCategory.addClass('not-active not-active-show');
    cache.$btnSubCategory.not(this).addClass('not-active not-active-show');

    $(this).removeClass('not-active');
    $(this).closest('.tab-pane').addClass('active');
    if (window.isMobile()) {
        cache.$colCategory.addClass('hide');
        cache.$btnBackMenu.removeClass('hide');
        cache.$headingTitleMenu.html(nameCategory);
        cache.$colLastSubCategory.addClass('translated-mobile');
        cache.$headingTitleMenu.html(nameCategory);
        cache.$btnBackMenu.attr('state-current', 'col-last-subcategory');
        cache.$btnBackMenu.attr('state-prev', 'col-sub-category');
    } else {
        cache.$colLastSubCategory.addClass('translated');
    }
});

// ACCORDIONS AND COLLASPSES, BLOCK ALL TAG EVENTS.
cache.$linkCountry.on('click', function (e) {
    e.preventDefault();

    // this url is set only for the "International Website" CTA
    // in order to bring the local to "en".
    let switchUrl = $(this).data('switch');
    let hrefUrl = $(this).attr('href');
    if (switchUrl) {
        $.ajax({
            url: switchUrl,
            type: 'POST',
            dataType: 'json',
            data: 'json',
            success: function (data) {
                if (!data.error) {
                    window.location.href = hrefUrl;
                }
            },
            error: function () {}
        });
    } else {
        window.location.href = hrefUrl;
    }
});

// BUTTON LOGIC GO BACK MENU
cache.$btnBackMenu.on('click', function () {
    var btnBackMenu = $(this);
    var titleMenu = cache.$headingTitleMenu.data('title').toUpperCase();
    if (btnBackMenu.attr('state-current') && btnBackMenu.attr('state-prev') && btnBackMenu.attr('state-name-prev')) {
        var current = btnBackMenu.attr('state-current');
        var prev = btnBackMenu.attr('state-prev');
        var namePrev = btnBackMenu.attr('state-name-prev');

        if (prev === 'col-category') {
            cache.$btnBackMenu.addClass('hide');
            $('.' + prev).removeClass('hide');
            $('.' + current).removeClass('translated');
            cache.$headingTitleMenu.html(titleMenu);
            cache.$btnCategory.removeClass('not-active active');
            cache.$linkCategory.removeClass('not-active active');
        }

        if (prev === 'col-sub-category') {
            btnBackMenu.attr('state-current', 'col-sub-category');
            btnBackMenu.attr('state-prev', 'col-category');
            $('.' + current).removeClass('translated-mobile');
            $('.' + prev).addClass('translated');
            cache.$headingTitleMenu.html(namePrev);
            cache.$btnSubCategory.removeClass('not-active active not-active-show');
            cache.$linkSubCategory.removeClass('not-active active not-active-show');
        }
    }
});

// PROMO HEADER LOGIC
cache.$campaignBannerClose.on('click', function () {
    cache.$campaignBanner.addClass('d-none');

    if (!window.isMobile()) {
        cache.$header.addClass('header-top');
    } else {
        cache.$logoMobile.addClass('header-top');
    }

    window.sessionStorage.setItem('hide_header_banner', '1');
    calculateComponentHeight();
});

// FUNCTION LOGIC CLOSE MENU
function closeMenu() {
    var titleMenu = cache.$headingTitleMenu.data('title').toUpperCase();
    if (window.isMobile()) {
        cache.$header.removeClass('mobile');
        cache.$body.removeClass('menu-open');
        $(window).scrollTop(heightPositionY);
        cache.$colCategory.removeClass('hide');
        cache.$btnBackMenu.removeAttr('state-current');
        cache.$btnBackMenu.removeAttr('state-prev');
        cache.$btnBackMenu.addClass('hide');
        cache.$headingTitleMenu.html(titleMenu);
    }

    cache.$body.removeClass('menu-open');
    cache.$colCategory.removeClass('translated');
    if (!window.isMobile()) {
        setTimeout(() => {
            cache.$navsMenu.addClass('hide');
        }, 300);
    } else {
        cache.$navsMenu.addClass('hide');
    }
    cache.$colSubCategory.removeClass('translated');
    cache.$colLastSubCategory.removeClass('translated translated-mobile');
    cache.$btnCategory.removeClass('not-active active');
    cache.$linkCategory.removeClass('not-active active');
    cache.$btnSubCategory.removeClass('active');
    cache.$btnSubCategory.addClass('not-active-show');
    cache.$linkSubCategory.addClass('not-active-show');

    if (cache.$page.data('action') === 'Product-Show') {
        cache.$header.removeClass('open-menu-page');
    }

    calculateComponentHeight();
}

// COUNTRY SELECTOR
cache.$btnCountrySelector.on('click', function () {
    var iconSpan = $(this).find('.icon-arrow-bottom');
    iconSpan.toggleClass('rotate');
});

cache.$countryCollapse.on('shown.bs.collapse', function () {
    cache.$firstCol.animate({
        scrollTop: cache.$colCategory[0].scrollHeight
    }, 'slow');
});

cache.$countryCollapse.on('hidden.bs.collapse', function () {
    cache.$firstCol.animate({
        scrollTop: cache.$colCategory[0].scrollHeight
    }, 'slow');
});

// FUNCTION TO CORRECT POSITION HEADER
function AddTopHeaderDesktop() {
    if (!window.isMobile() && window.scrollY !== 0) {
        cache.$header.addClass('scroll-header');
        let diff = cache.$campaignBanner && cache.$campaignBanner[0]
            ? cache.$campaignBanner[0].offsetHeight - window.scrollY
            : 0;

        if (diff > 0) {
            cache.$header.css('margin-top', (cache.$campaignBanner[0].offsetHeight - window.scrollY) + 'px');
        } else {
            cache.$header[0].style.removeProperty('margin-top');
        }
    } else {
        cache.$header.removeClass('scroll-header');
        cache.$header[0].style.removeProperty('margin-top');
    }
}

function AddTopHeaderLogoMobile() {
    if (window.isMobile() && window.scrollY !== 0) {
        cache.$logoMobile.addClass('scroll-logo-mobile');
        var diff = cache.$campaignBanner && cache.$campaignBanner[0]
            ? cache.$campaignBanner[0].offsetHeight - window.scrollY
            : 0;

        if (diff > 0) {
            cache.$logoMobile.removeClass('scroll-logo-mobile');

            cache.$logoMobile.css('margin-top', 0);

            if (cache.$logoMobile.hasClass('only-red')) {
                cache.$logoMobile.css('position', 'static');

                if ($('.pd-search-show .title-category').length > 0) {
                    $('.pd-search-show .title-category').css('margin-top', 0);
                }
            }

            if ($('.js-swiper-pupa-story').length > 0) {
                cache.$logoMobile.removeClass('scroll-logo-mobile');
                cache.$logoMobile.css('position', 'absolute');
                cache.$logoMobile.css('margin-top', 0);
            }

            if ($('.page-homepage').length > 0) {
                cache.$logoMobile.css('position', 'absolute');
            }
        } else {
            cache.$logoMobile.addClass('scroll-logo-mobile');
            cache.$logoMobile[0].style.removeProperty('margin-top');
            if (cache.$logoMobile.hasClass('only-red')) {
                cache.$logoMobile.css('position', 'fixed');

                if ($('.pd-search-show .title-category').length > 0) {
                    $('.pd-search-show .title-category')[0].style.removeProperty('margin-top');
                }
            }

            if ($('.js-swiper-pupa-story').length > 0) {
                cache.$logoMobile.css('position', 'fixed');
                cache.$logoMobile.css('margin-top', 0);
            }
        }
    } else {
        cache.$logoMobile.removeClass('scroll-header');
        cache.$logoMobile[0].style.removeProperty('margin-top');
    }
}

// the scroll takes into account the presence of the header
$(document).ready(function () {
    if (window.location.hash) {
        let targetElement = $(window.location.hash);

        if (targetElement && targetElement.offset()) {
            $("html, body").animate({scrollTop: targetElement.offset().top - 120}, 1000);
        }
    }
});
